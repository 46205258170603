










































































































.hexagon-background {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  svg {
    position: absolute;
    top: 0;
    width: 120%;
    height: 100%;
  }
}
