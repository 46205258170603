









































































































@import '~@/assets/style/variables';

.clans-test {
  h2 {
    color: $dark;
  }
}
