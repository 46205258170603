





































































































































@import '~@/assets/style/variables';

.question {
  position: relative;
  background-color: $white;
  color: $dark;
  padding: $gutter $gutter-md;
  text-align: center;

  border-radius: 5px;
  @include card(3);

  &__question {
    font-size: $font-size-3;
    line-height: 1.2em;
    padding-bottom: $gutter-md;
  }

  ul {
    li {
      margin-bottom: $gutter-sm;
    }
  }
}
