



































































































@import '~@/assets/style/variables';

.member-card {
  background-color: $grey-100;
  color: $dark;
  overflow: hidden;
  border-radius: $gutter-xs;

  min-width: 290px;
  max-width: 300px;

  &-container {
    padding: $gutter;
  }

  @include card(2);

  h4 {
    text-transform: uppercase;
    text-align: center;
    padding: $gutter 0;
    background-color: $primary-color;
    color: $white;
  }

  strong {
    font-weight: 500;
  }

  .barcode {
    height: auto;
    width: 100%;
  }

  &__avatar {
    $avatar-size: 5rem;
    display: flex;
    width: 100%;
    height: $avatar-size;

    img {
      width: $avatar-size;
      height: $avatar-size;
      border-radius: 50%;
    }

    p {
      font-size: $font-size-2;
      margin-left: $gutter;
      line-height: 1.5rem;
    }
  }

  &__details {
    padding: $gutter 0;
    li {
      line-height: 1.1rem;
    }

    &-username {
      font-size: $font-size-2;
    }
  }
}
