





























































@import '~@/assets/style/variables';

.platform-home {
  .line-up {
    border-top: 1px solid rgba(236,232,225,.4);
  }
}
