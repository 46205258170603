


























































































@import '~@/assets/style/variables';

.leaderboard-card {
  background-color: $dark;
  color: $white;
  padding: $gutter;
  border-radius: $gutter-xs;

  h4 {
    text-transform: uppercase;
  }

  &__clan {
    text-align: center;

    svg {
      max-width: 100px;
    }

    h3 {
      small {
        font-weight: 100;
        font-size: 0.8rem;
      }
    }
  }

  @include card(2);
}
