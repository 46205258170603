






































@import '~@/assets/style/variables';

.clans {
  &__main {
    background-color: $dark;
  }
}
