
































































@import '~@/assets/style/variables';

.clan-card {
  background-color: $dark;
  color: $white;
  padding: $gutter;
  border-radius: $gutter-xs;
  width: fit-content;

  h4 {
    text-transform: uppercase;
  }

  &__picture {
    height: 300px;
    width: 300px;
    background-color: $pink-900;
    overflow: hidden;

    font-size: 25rem;
    line-height: 25rem;

    border-radius: 5px;
  }

  @include card(2);
}
