










































@import '~@/assets/style/variables';

.platform {
  background-color: $pink;
  // background-image: url('/img/bgs/bg_japan_004.png');
  // background-position: right bottom;
  // background-repeat: no-repeat;
  // background-size: 750px auto;
}
